import store from '@/store'
export default {
    /** 获取登录用户账号缓存
     * @param {Object} k[键名]
     */
    getuser: function () {
        return store.state.name
    },

    /**存储缓存
     * @param  data
     */
    putuser: function (data) {
        store.commit("setName", data)
    }
}
