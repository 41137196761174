import { config } from "@/config/w6s.config.js"
import store from '@/store'
// 全量引入
import * as w6s from "@w6s/sdk"
import cache from "@/utils/cache"
export function init() {
    const statusBar = navigator.userAgent
        .split(" ")
        .find((item) => item.indexOf("statusBar") > -1)
    console.log("statusBar", statusBar)
    if (statusBar) {
        let height = statusBar.split("/")[1]
        store.commit('setHeight', height);
    }
    let promise = config(w6s)
    return new Promise((resolve, reject) => {
        promise
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export function Isinit() {
    return cache.getuser()
}
