let baseUrl,dac
process.env.NODE_ENV == "development"
    ? (baseUrl = "http://localhost:51972")
    : (baseUrl = "https://www.lcfcsgx.ink")
// 默认分支
const ENV = window.ENV || "dev"
// 默认版本
const version = window.versionServer || "v1"
ENV=="prd"? (dac="dac"):(dac=`dac-${ENV}`)

export default {
    method: "post",
    // 基础url前缀`
    baseUrl: `${baseUrl}/`, //请求的域名地址
    // 请求头信息
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "X-App": dac,
        "X-Auth-Meta":
            "eyJ1c2VySWQiOiJtZXMtODg1NTE5OTQzNGI4ZjcxMWQxNDY3OTI2N2Y1IiwidXNlck5hbWUiOiJvYW1vYmlsZUBsY2ZjLmNvbSIsImVudiI6ImRldiIsImZhY3RvcnlJZCI6bnVsbCwicm9sZXMiOltdLCJ0ZW5hbnRJZCI6bnVsbCwiQWxpYXMiOiJvYW1vYmlsZUBsY2ZjLmNvbSJ9",
        "X-Env": ENV,
        "X-Service": "mobileplatform",
        "X-Skip-Verify": "mestest",
        "X-Version": version,
        "X-Rule": "None"
    },
    // 设置超时时间
    timeout: 10000 * 20,
    // 携带凭证
    withCredentials: false,
    // 返回数据类型
    responseType: "json"
}
