import axios from "@/service/http/axios"
// 消息列表
export const getList = (data) =>
    axios({
        url: `api/message/record`,
        method: "post",
        data
    })
// 读取消息
export const getDetail = (data) =>
    axios({
        url: `api/message/read`,
        method: "post",
        data
    })

//处理接口
export const handle = (data) =>
    axios({
        url: `PushMessage-Handle`,
        method: "post",
        data
    })
