<template>
    <div>
        <div>
            <van-nav-bar safe-area-inset-top fixed placeholder :title="title" :left-arrow="left_arrow"
                @click-left="clickleft">
                <template #right>
                    <slot name="right"></slot>
                </template>
            </van-nav-bar>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        left_arrow: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        clickleft() {
            this.$emit("click-left")
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar {
    background: #1989fa;
    $statusBarheight: var(--height);
    $height: calc($statusBarheight + 10px);
    padding-top: $statusBarheight;
    height: $height;

    .van-icon {
        color: $base-color;
    }

    .van-nav-bar__title {
        color: white;
        font-size: 18px;
    }

    .van-icon-arrow-left:before {
        color: white;
    }
}
</style>
