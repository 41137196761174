<template>
    <div>
        <div class="contain">
            <span class="label"></span>
            <span class="title">{{ title }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
.contain {
    display: flex;
    align-items: center;
}

.label {
    background: black;
    width: 1px;
    height: 15px;
    border: 1px solid black;
    border-radius: 10px;
}

.title {
    margin-left: 10px;
    font-size: 15px;
}
</style>
