<template>
    <div class="container">
        <nav-bar title="QDT消息通知" @click-left="clickleft"></nav-bar>
        <van-tabs v-model="active">
            <van-tab title="未读" :badge="totalone">
                <alarm id="01" state="unread" @total="showbadge"></alarm>
            </van-tab>
            <van-tab title="已读" :badge="totaltwo">
                <alarm id="02" state="read" @total="showbadge"></alarm>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import alarm from "./alarm.vue"
export default {
    components: {
        alarm
    },
    data() {
        return {
            totalone: "",
            totaltwo: "",
            totalthree: ""
        }
    },
    methods: {
        clickleft() {
            this.$w6s.webview.exit()
        },
        showbadge(e) {
            let id = e.id
            if (id == "01") {
                this.totalone = e.num
            } else if (id == "02") {
                this.totaltwo = e.num
            } else {
                this.totalthree = e.num
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
}
</style>
