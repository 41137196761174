import axios from "axios"
import config from "./config"

const instance = axios.create({
    baseURL: config.baseUrl,
    headers: config.headers,
    timeout: config.timeout
})

// request 拦截器
instance.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
/**
 * request方法
 */
const request = (obj) =>
    instance({
        headers: {
            "X-Fn": obj.url
        },
        method: obj.method,
        data: obj.data,
        url: obj.url,
    })

// response 拦截器
instance.interceptors.response.use(
    (response) => {
        return response.data
    },
    (err) => {
        return Promise.reject(err)
    }
)

export default request
