<template>
    <div>
        <nav-bar title="消息详情" @click-left="onClickLeft"> </nav-bar>
        <div v-if="loadfinish" class="contain">
            <div class="cotentbg">
                <base-info-title :title="title"></base-info-title>
                <div class="left">
                    <base-info-label label="描述:" :content="content"></base-info-label>
                    <span class="time">{{ time }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import baseInfoTitle from "@/components/base-info-title"
import baseInfoLabel from "@/components/base-info-label"
import { getDetail } from "@/service/api/alarm"
import { handleResponse } from "@/utils/common"
export default {
    components: {
        baseInfoTitle,
        baseInfoLabel
    },
    data() {
        return {
            id: null,
            content: "内容",
            loadfinish: true,
            time: "时间",
            title: "标题"
        }
    },
    mounted() {
        this.title = this.$route.query.title
        this.content = this.$route.query.content
        this.time = this.$route.query.time
        this.id = this.$route.query.id
        let data = { id: this.id }
        getDetail(data)
            .then((res) => {
                handleResponse(res)
            })
            .catch((err) => {
                console.log("失败" + err)
                //联网失败, 结束加载
                this.$LoadView.showToast(this, err.message)
            })
    },
    setup() {
        const onClickLeft = () => {
            if (window.history.length <= 1) {
                this.$w6s.webview.exit()
            } else {
                history.back()
            }
        }
        return {
            onClickLeft,
        };
    },

}

</script>

<style lang="scss" scoped>
.contain {
    box-sizing: border-box;
    display: flex;
    padding: 10px;
    flex-direction: column;
    background-color: $common-bg-color;
    height: 100vh;
}

.cotentbg {
    padding: 10px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
    border-width: 1px;
}

.dec {
    font-size: 16px;
    color: $common-desc-text-color;
    margin-bottom: 15px;
    text-align: left;

}

.statebg {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.left {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.time {
    width: 100%;
    text-align: right;
    color: $font-color-spec;
    font-size: 15px;
}
</style>
