<template>
    <div class="contain" @click="jumpToDetail">
        <img class="left-icon" :src="imgpath" />
        <div class="contain-right">
            <span class="alarm-title">{{ info.title }}</span>
            <div class="title-line-style">
                <span class="label"></span>
                <span class="content">{{ info.content }}</span>
            </div>
            <span class="right-time">{{ info.time }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => { }
        }
    },

    computed: {
        imgpath() {
            let src = require("@/assets/images/icon_alarm_yellow.png")
            // if (this.info.statuS_FLAG == 1) {
            //     src = require("@/assets/images/icon_alarm_red.png")
            // } else if (this.info.statuS_FLAG == 2) {
            //     src = require("@/assets/images/icon_alarm_yellow.png")
            // } else if (this.info.statuS_FLAG == 3) {
            //     src = require("@/assets/images/icon_alarm_green.png")
            // }
            if (this.info.level == "urgent") {
                src = require("@/assets/images/icon_alarm_red.png")
            } else if (this.info.level == "normal") {
                src = require("@/assets/images/icon_alarm_green.png")
            }
            return src
        }
    },
    methods: {
        jumpToDetail() {
            this.$router.push({
                name: "messagedetail",
                query: {
                    content: this.info.content,
                    title: this.info.title,
                    time: this.info.time,
                    id: this.info.id,
                }
            })
            
        }
    }
}
</script>

<style lang="scss" scoped>
.contain {
    margin-top: 20px;
    padding: 10px;
    width: auto;
    display: flex;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
    border-width: 1px;
    margin: 10px;
}

.left-icon {
    width: 30px;
    height: 30px;
    margin-left: 5px;
}

.contain-right {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.title-line-style {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.alarm-title {
    color: #222222;
    margin-left: 5px;
    font-size: 15px;
    width: 90%;
    text-align: left;
}

.label {
    font-size: 15px;
    text-align: left;
    color: #999999;
}

.content {
    font-size: 15px;
    color: #999999;
}

.right-time {
    width: 100%;
    font-size: 13px;
    text-align: right;
    color: #999999;
}

span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
