import loadview from "./loadview"
import cache from "@/utils/cache"
import navBar from "@/components/nav-bar/index.vue"

const install = (App) => {
    if (install.installed) return
    install.installed = true
    App.component("nav-bar", navBar)
    App.config.globalProperties.$cache = cache
    App.config.globalProperties.$router = loadview
}

export default install
