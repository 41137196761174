<template>
    <div class="container">
        <nav-bar title="错误" @click-left="onClickLeft" />
        <span>初始化失败</span>
    </div>
</template>

<script>
export default {
    methods: {
        onClickLeft() {
            this.back()
        },
        back() {
            if (window.history.length <= 1) {
                this.$w6s.webview.exit()
            } else {
                this.$router.back()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
</style>
