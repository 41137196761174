import { Toast } from "vant"

export function getQueryVariable(searchURL, name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
    let url = searchURL.split("?")[1].match(reg)
    // console.log(url)
    if (url != null) {
        return decodeURI(url[2]) //decodeURI() 函数可对 encodeURI() 函数编码过的 URI 进行解码。
    } else {
        return null
    }
}

export function handleResponse(res) {
    const { data, errMsg, errCode } = res
    if (errCode === "true") {
        return data
    } else {
        const msg = errMsg
        Toast.fail(msg)
        return false
    }
}
