<template>
    <div>
        <!--mescroll滚动区域的基本结构-->
        <mescroll-vue :id="Id" ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
            <div v-for="(item, index) in dataList" :key="index">
                <alarm-Item :info="item" />
            </div>
        </mescroll-vue>
    </div>
</template>

<script>
import MescrollVue from "mescroll.js/mescroll.vue"
import alarmItem from "@/components/alarm-item/index.vue"
import { getList } from "@/service/api/alarm"
import { handleResponse } from "@/utils/common"
export default {
    components: {
        alarmItem,
        MescrollVue
    },
    data() {
        return {
            dataList: [],
            mescroll: null, // mescroll实例对象
            No: 0,
            page: 10,
            stage: this.state,// 消息状态，为未读和已读
            statusBar: 0,
            mescrollDown: {
                callback: this.downCallback,
                auto: true,
                isBounce: false
            },
            mescrollUp: {
                callback: this.upCallback,
                auto: false,
                isBounce: false,
                noMoreSize: 0,
                page: {
                    num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
                    size: 10 //每页数据条数,默认10
                },
                empty: {
                    //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
                    warpId: this.Id, //父布局的id (1.3.5版本支持传入dom元素)
                    tip: "暂无相关数据~" //提示
                }
            }
        }
    },
    props: {
        Id: {
            type: String,
            default: ""
        },
        state: {
            type: String,
            default: "unread"
        }
    },
    activated() {
        this.downCallback()
    },
    methods: {
        mescrollInit(mescroll) {
            console.log("mescroll create success!")
            this.mescroll = mescroll // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
        },
        downCallback() {
            this.mescroll.resetUpScroll(true)
        },
        upCallback(page) {
            let data = {
                no: page.num,
                page: this.page,
                stage: this.stage,
                userId: this.$cache.getuser()
            }
            getList(data)
                .then((res) => {
                    console.log("开始了3")
                    const data = handleResponse(res)
                    if (data) {
                        let list = data.data
                        //设置列表数据
                        if (page.num == 1) {
                            this.dataList = [] //如果是第一页需手动制空列表
                        }
                        this.dataList = this.dataList.concat(list) //追加新数据
                        // 数据渲染成功后,隐藏下拉刷新的状态
                        this.mescroll.endSuccess(list.length)
                        this.total(data.total)
                    } else {
                        //联网失败, 结束加载
                        this.mescroll.endErr()
                    }
                })
                .catch((err) => {
                    console.log("失败啦" + err)
                    //联网失败, 结束加载
                    this.mescroll.endErr()
                    this.$LoadView.showToast(this, err.message)
                })
        },
        total(e) {
            let total = ""
            if (e != "0") {
                total = e
            }
            let param = {
                id: this.Id,
                num: total
            }
            this.$emit("total", param) //select事件触发后，自动触发showCityName事件
        }
    }
}
</script>

<style lang="scss" scoped>
.mescroll {
    position: fixed;
    $height: var(--height);
    $top: calc($height + 100px);
    top: $top;
    bottom: 20px;
    height: auto;
}
</style>
