<template>
    <div class="title-line-style">
        <span class="alarm_desc_left">{{ label }}</span>
         <span class="alarm_desc_right">{{ content }}</span>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        content: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
.title-line-style {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
    padding-top: 5px;
}

.alarm_desc_left {
    font-size: 15px;
    color: $common-desc-text-color;
}

.alarm_desc_right {
    text-align: left;
    margin-left: 5px;
    font-size: 15px;
    width: 0;
    flex: 1;
    word-break: break-all;
    white-space:pre-wrap;
    color: $common-desc-text-color;
}
</style>
