import { createStore } from 'vuex'

// 创建一个新的 store 实例
const store = createStore({
  state() {
    return {
      statusBarheight: "--height:90px",
      name: ""
    }
  },
  mutations: {
    setHeight(state, height) {
			state.statusBarheight = "--height:" + height + "px";
		},
    setName(state, id) {
      state.name = id
    }
  }
})
export default store
