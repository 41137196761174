<template>
  <div class="home">
    <main-home v-if="active == 0"></main-home>
  </div>
</template>

<script>
// @ is an alias to /src
import mainHome from "@/views/home/index.vue"

export default {
  name: 'HomeView',
  components: {
    mainHome
  },
  data() {
    return {
      active: 0
    }
  }
}
</script>
