// 引入模块
import sha1 from "crypto-js/sha1"
import cache from "@/utils/cache"

export function config(w6s) {
    //获取用户信息
    const accessSecret = "c5e79e9d410147b1a8bf5fff037d892a"
    const timestamp = Date.parse(new Date())
    const nonce = String(timestamp).slice(0, 6)
    const str = [accessSecret, nonce, timestamp]
        .sort()
        .reduce((str, item) => (str += item), "")
    const signature = sha1(str).toString().toLowerCase()
    const promise = new Promise((resolve, reject) => {
        w6s.config({
            access_key: "eec7337cc7794b8cb399b65edf5f2c87",
            nonce: nonce,
            timestamp: timestamp,
            signature: signature,
        })
            .then(() => {
                // 成功
                w6s.user.getCurrentUserInfo({
                    needEmpInfo: true,
                    success: function (res) {
                        cache.putuser(res.result.employee.username)
                        // 接口请求成功时调用
                        resolve(res)
                    },
                    fail: function (err) {
                        reject(err)
                    }
                })
            })
            .catch((err) => {
                // 失败
                reject(err)
            })
    })
    return promise
}
