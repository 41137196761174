import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import View from './utils/view'
import {
    NavBar,
    Tab,
    Tabs,
    Toast,
} from "vant"
import * as w6s from "@w6s/sdk"
w6s.init({
    debug: true,
    auth: true,
    timeout: 1000 * 10
});
const app = createApp(App)
    .use(store)
    .use(router)
    .use(View)
    .use(NavBar)
    .use(Tab)
    .use(Tabs)
    .use(Toast)
app.config.globalProperties.$store = store
app.config.globalProperties.$router = router
app.config.globalProperties.$w6s = w6s

app.mount('#app')
