import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/main/index.vue'
import messagedetail from "../views/messagedetail/index.vue"
import error from "../views/error"
import { Isinit, init } from "@/config/init"
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/messagedetail",
    component: messagedetail,
    name: "messagedetail"
  },
  {
    path: "/error",
    component: error,
    name: "error"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 拦截器
router.beforeEach((to, from, next) => {
  if (!Isinit()) {
    init().then((res) => {
      console.log("鉴权成功" + res)
      next()
    }).catch((err) => {
      console.log("鉴权失败" + err)
      next({ path: "/error", replace: true })
    })
  } else {
    next()
  }
})
export default router
