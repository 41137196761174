export default {
    /**显示toast
     * @param {Object} data内容
     */
    showToast: function (context, data) {
        context.$toast(data)
    },

    /**显示 loading 提示框
     * @param {Object} data
     */
    showLoading: function (context, data) {
        context.$toast.loading({
            message: data,
            forbidClick: true
        })
    },

    /**隐藏 loading 提示框
     * @param {Object} data
     */
    hideLoading: function hideLoading(context) {
        context.$toast.clear()
    }
}
